body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.formLabel {

  margin-bottom: 100px;
}

.Dropdown-option {
  background: ghostwhite;
  /* margin-left: 10px; */
  padding-left: 10px;
  border-bottom: 1px solid #dee2e6;
}

.Dropdown-menu {
  width: 110%;
  margin: 0px;
  margin-top: 10px;
  margin-left: -10px;
}

.dashHeader {
  font-size: 20px;

}

caption {
  caption-side: top;
}