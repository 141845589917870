tr.inactive td ul {
  max-height: 0;
  overflow: hidden;
  transition: all 0.4s;
}

tr td ul {
  max-height: 1000px;
  transition: all 2s;
}
